<!-- 我的订单 -->
<template>
    <div class="container my-order-container">
        <titleText title="我的订单"/>

        <el-tabs v-model="active" @tab-click="tabChangeFn">
            <el-tab-pane v-for="(item, index) in tabsItem" :key="index" 
                :label="item.name" :name="item.status">
            </el-tab-pane>
        </el-tabs>

        <div>
            <div class="th flex align-c">
                <div class="th-info">订单信息</div>
                <div class="th-price">金额</div>
                <div class="th-amount">订单状态</div>
                <div class="th-sum">操作</div>
            </div>
            <div class="mt-20">
                <div class="tr flex font-12 " v-for="(item, index) in orderList" :key="index">
                    <div class="tr-info">
                        <p class="color-999">
                            <span class="mr-30">{{item.createTime}}</span>
                            <span>订单号：</span>
                            <span class="color-333">{{item.ordersn}}</span>
                        </p>
                        <div class="good-wrapper">
                            <p>
                                <span>{{item.orderProducts[0].productName}}</span>
                                <span v-if="item.orderProducts.length > 1">等<i class="color-orange">{{item.orderProducts.length}}种</i>商品</span>
                            </p>
                            <div class="flex align-c mt-10">
                                <div v-if="i<5" v-for="(good, i) in item.orderProducts" :key="i" class="good-image" @click="toGoodDetail(good.productId)">
                                    <el-image :src="good.thumb || 'https://o.rjkj7.com/data/upload/xcximages/xiangdon/good_default.jpg'" lazy style="width: 88px; height: 88px;">
                                        <img slot="placeholder" src="https://o.rjkj7.com/data/upload/xcximages/xiangdon/good_default.jpg" style="width: 88px; height: 88px;">
                                    </el-image>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tr-price">
                        <span>￥{{(item.orderprice + item.dispatchprice - item.bargainprice).toFixed(2)}}</span>
                    </div>
                    <div class="tr-amount">
                        <span>{{STATUS_TEXT[item.status]}}</span>
                    </div>
                    <div class="tr-sum flex-column">
                        <template v-if="item.status == 0">
                            <span @click="cancelOrder(index)">取消订单</span>
                            <span @click="toOrderDetail(item.id)">立即支付</span>
                        </template>
                        <template v-if="item.status == 1">
                            <span @click="toOrderDetail(item.id)">查看详情</span>
                            <span @click="cancelOrder(index)">取消订单</span>
                        </template>
                        <template v-if="item.status == -1 || item.status == 2 || item.status == 3 || item.status == 4 || item.status == 5 || item.status == 6">
                            <span @click="toOrderDetail(item.id)">查看详情</span>
                        </template>
                        <template v-if="(item.status == 3 || item.status == 5) && item.commentStatus == 0">
                            <span @click="toEvaluation(item.id)">评价</span>
                        </template>
                    </div>
                </div>
                <Empty v-if="orderList.length == 0" title="抱歉，没有找到符合条件的订单" />
            </div>
        </div>

        <el-pagination v-if="total>perPage" @current-change="pageChange" :current-page="currPage" :page-size="perPage"
                :total="total" layout="total, prev, pager, next, jumper" background>
        </el-pagination>
    </div>
</template>

<script>
import titleText from '@/components/titleText'
import Empty from '@/components/empty'

const STATUS_TEXT = {
  '-1': "已取消",
  0: "待付款",
  1: "待发货",
  2: "待收货",
  25: "部分发货",
  3: "待评价",
  4: "待退款",
  5: "售后中",
  6: "已完成",
}
export default {
    name: 'myOrder',
    components: { 
        titleText,
        Empty
    },

    data() {
        return {
            STATUS_TEXT: STATUS_TEXT,
            tabsItem: [{
                name: '全部',
                status: '-1',
            },{
                name: '待付款',
                status: '0',
            },{
                name: '待发货',
                status: '1',
            },{
                name: '待收货',
                status: '2',
            },{
                name: '已完成',
                status: '6',
            },
            {
                name: '待评价',
                status: '3',
            }],
            active: '-1',
            orderList: [],
            currPage: 1,
            perPage: 5,
            total: 0
        };
    },

    created() {
        this.getOrderList(false)
    },

    methods: {
        //tab切换
        tabChangeFn() {
            this.getOrderList(false)
        },

        getOrderList(status, type) {
            let userInfo = this.$store.state.userInfo
            if(!status) this.currPage = 1
            if(type && this.active != '-1' && this.currPage > 1 && this.orderList.length == 1) this.currPage--
            this.$https.get(this.$api.orderList,{
                mid: userInfo.id,
                status: this.active == '-1' ? '' : this.active,
                offset: (this.currPage - 1) * this.perPage,
                limit: this.perPage
            })
            .then((res) =>{
                this.orderList = res.data.rows
                this.total = res.data.total
                if(type) return
                document.body.scrollIntoView()
            })
        },

        //分页
        pageChange(val) {
            if(this.currPage != val){
                this.currPage = val
                this.getOrderList(true)
            }  
        },

        //跳转商品详情
        toGoodDetail(id) {
            let { href } = this.$router.resolve({
                                name: "goodDetail",
                                query: {
                                    id: id
                                }
                            });
            window.open(href, '_blank')
        },

        //取消订单
        cancelOrder(index) {
            let order = this.orderList[index]
            if (order.status === 1) { //订单已支付
                let cancelAble = true;
                for (let i = 0; i < order.orderProducts.length; i++) {
                    if (order.orderProducts[i].uncancelable === 1) {//订单里存在不支持无理由退单
                        cancelAble = false;
                        break;
                    }
                };
                if (!cancelAble) {
                    this.$msgbox.alert('有不支持无理由退单商品，无法取消订单', '提示', {
                        confirmButtonText: '确定',
                        type: 'warning'
                    })
                    return;
                }
            }

            this.$confirm('确定取消该订单吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$https.post(this.$api.orderCancel,{
                    orderId: order.id,
                })
                .then((res) =>{
                    this.$message({
                        message: '取消订单成功',
                        type: 'success'
                    })
                    this.getOrderList(true, true)
                })   
            }).catch(() => {})
        },

        //订单详情
        toOrderDetail(id){
            this.$router.push({
				name: 'orderDetail',
				query: {
					id: id
				}
			})
        },

        //评价
        toEvaluation(id) {
            this.$router.push({
				name: 'evaluation',
				query: {
					id: id
				}
			})
        }
    }
}
</script>
<style lang='scss' scoped>
    .th{
        height: 36px;
        padding: 0 20px;
        text-align: center;
        border: 1px solid #DEDEDE;
        background-color: #f1f1f1;
        .th-info{
            flex: 0 0 580px;
        }
        .th-price{
            flex: 0 0 200px;
        }
        .th-amount{
            flex: 0 0 180px;
        }
        .th-sum{
            flex: 0 0 198px;
        }
    }
    .tr{ 
        margin-bottom: 10px;
        padding: 0 20px;
        border: 1px solid #DEDEDE;
        > div{
            padding: 20px 0;
        }
        .tr-info{
            flex: 0 0 580px;
            border-right: 1px solid #dedede;
            .good-wrapper{
                padding-top: 18px;
                margin: 15px 20px 0 0;
                border-top: 1px dashed #dedede;
            }
            .good-image{
                cursor: pointer;
                border: 1px solid #DEDEDE;
                margin-right: 10px;
            }
        }
        .tr-price{
            flex: 0 0 200px;
            text-align: center;
        }
        .tr-amount{
            flex: 0 0 180px;
            text-align: center;
        }
        .tr-sum{
            flex: 0 0 198px;
            text-align: center;
            > span{
                cursor: pointer;
                margin-top: 13px;
                &:first-child{
                    margin-top: 0;
                }
                &:hover{
                    color: var(--blue-color);
                }
            }
        }
    }
</style>